<template>
  <div>
    <v-row class="mx-3 pt-1">
      <v-col cols="12" lg="6" md="5" sm="7" xs="12" class="d-flex justify-center align-center justify-sm-start pt-2">
        <span class="text-overline pt-1 mr-2">Prioridad </span>
        <v-chip
          v-for="(prioridad, index) in prioridades"
          v-bind:key="index"
          class="mx-1"
          :color="prioridad.color"
          text-color="white"
          small
          label
        >
          {{ prioridad.nombre }}
        </v-chip>
      </v-col>
      <v-col
        cols="12"
        lg="3"
        md="3"
        sm="4"
        offset-lg="0"
        offset-md="0"
        offset-sm="0"
        class="d-flex justify-center justify-sm-end justify-md-start align-center pt-0"
      >
        <v-autocomplete
          v-model="responsablesFiltro"
          full-width
          dense
          :hide-details="true"
          hide-selected
          :items="listadoResponsablesXPermisos"
          @change="listarTareas"
          class="mr-2 pt-0"
          label="Responsables..."
          item-text="nombres"
          item-value="value"
          :return-object="false"
          style="font-size: 13.5px;"
        >
        </v-autocomplete>
        <v-tooltip bottom>
          <template v-slot:activator="{ on: tooltip }">
            <v-btn color="primary" class="mt-1" icon @click="listarTareas" v-on="{ ...tooltip }" right>
              <v-icon>mdi-sync-circle</v-icon>
            </v-btn>
          </template>
          <span>Actualizar Tablero</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on: tooltip }">
            <v-btn
              color="primary"
              class="mr-2"
              icon
              @click="panel.length == 0 ? allPanel() : nonePanel()"
              v-on="{ ...tooltip }"
              right
            >
              <v-icon>{{ panel.length == 0 ? 'mdi-arrow-expand' : 'mdi-arrow-collapse' }}</v-icon>
            </v-btn>
          </template>
          <span>{{ panel.length == 0 ? 'Expandir' : 'Contraer' }} Tablero</span>
        </v-tooltip>
      </v-col>
      <!-- offset-lg="4" offset-md="2"  -->
      <v-col cols="12" lg="3" md="4" sm="4" offset-sm="0" class="d-flex justify-center justify-md-end">
        <span class="text-button mr-6">{{ 'Semana ' + (semanaActual ? semanaActual : '') }}</span>
        <v-tooltip bottom>
          <template v-slot:activator="{ on: tooltip }">
            <v-btn small color="primary" @click="openDialogSincronizacion" v-on="{ ...tooltip }" right>
              <v-icon class="mr-1">mdi-sync-circle</v-icon>
              OKR
            </v-btn>
          </template>
          <span>Sincronización con OKR</span>
        </v-tooltip>
      </v-col>
    </v-row>
    <v-col cols="12" sm="12" lg="12">
      <v-expansion-panels v-model="panel" class="kanban-expansion-panels mb-6 l-5" multiple accordion>
        <v-expansion-panel v-for="(item, index) in listas" v-bind:key="index">
          <v-expansion-panel-header class="py-0 pl-3 pr-10" justify-self-start>
            <template v-slot:actions>
              <v-icon class="expansion-panel-icon py-3" :color="item.color">
                mdi-arrow-down-drop-circle
              </v-icon>
            </template>
            <v-row class="expansion-panel-header pl-3">
              <div
                :class="
                  (item.abreviatura != estados.SIN_ESTADO
                    ? 'col-5 col-sm-8 col-lg-5 '
                    : 'col-5 col-lg-10 col-md-8 col-sm-8 ') + ' pa-0 py-3 text-caption text-sm-body-2 d-flex'
                "
              >
                <span class="text-left">{{ item.nombre.split(': ')[0] }}</span>
                <span class="text-left d-none d-md-block">
                  {{ item.nombre.split(': ').length > 1 ? ': ' + item.nombre.split(': ')[1] : '' }}</span
                >
              </div>
              <div
                v-if="item.abreviatura != estados.SIN_ESTADO"
                class="col-4 pa-0 py-3 col-lg-1 col-md-2 col-sm-2 text-center text-caption text-sm-body-2"
              >
                Responsable
              </div>
              <div
                v-if="item.abreviatura != estados.SIN_ESTADO"
                class="col-2 pa-0 py-3 text-center kanban-celda-left text-caption text-sm-body-2 d-none d-lg-block"
              >
                Estado
              </div>
              <div
                v-if="item.abreviatura != estados.SIN_ESTADO"
                class="col-2 pa-0 py-3 text-center kanban-celda-right text-caption text-sm-body-2 d-none d-lg-block"
              >
                Fecha
              </div>
              <div
                v-if="item.abreviatura != estados.SIN_ESTADO"
                class="col-2 col-lg-1 pa-0 py-3 text-center text-caption text-sm-body-2 d-none d-lg-block"
              >
                Archivos
              </div>
              <div
                v-if="item.abreviatura == estados.SIN_ESTADO"
                class="col-4 pa-0 py-3 col-lg-1 col-md-2 col-sm-2 text-center text-caption text-sm-body-2"
              >
                Prioridad
              </div>
              <!--<div class="col-1 pa-0 py-3 text-center">Opciones</div>-->
            </v-row>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <Draggable
              ghost-class="ghost"
              tag="ul"
              class="w-full max-w-md px-3"
              :list="item.actividades"
              :animation="200"
              group="activities"
              @start="startDrag"
              @end="endDrag"
              @change="changeDraggable"
              easing="cubic-bezier(1, 0, 0, 1)"
            >
              <transition-group type="transition" :name="!drag ? 'flip-list' : ''">
                <li
                  class="list-group-item pa-0"
                  draggable
                  elevation="2"
                  v-for="(actividad, index2) in item.actividades"
                  v-bind:key="index2"
                >
                  <v-alert class="kanban-alert" border="left" colored-border :color="item.color">
                    <v-row>
                      <div
                        :class="
                          (item.abreviatura != estados.SIN_ESTADO
                            ? 'col-5 col-sm-8 col-lg-5 '
                            : 'col-5 col-lg-10 col-md-8 col-sm-8 pt-3 ') + ' pr-lg-6 pr-0'
                        "
                        @click="focusInputNombre(index, index2, item.abreviatura)"
                      >
                        <div class="d-flex justify-space-between align-center">
                          <span
                            v-if="!editing[`${item.abreviatura}${index2}nombre`]"
                            @click="edit(item.abreviatura + index2 + 'nombre', item.abreviatura)"
                            class="pt-2"
                          >
                            {{ actividad.nombre }}
                          </span>
                          <v-text-field
                            v-else
                            :ref="`${item.abreviatura}${index2}nombre`"
                            @blur="exitEdit(item.abreviatura + index2 + 'nombre', actividad)"
                            @focus="focusInputNombre(index, index2, item.abreviatura)"
                            v-on:keyup.enter="exitEdit(item.abreviatura + index2 + 'nombre', actividad)"
                            style="font-size: 13px;"
                            class="mt-0 uppercase"
                            small
                            dense
                            hide-details="auto"
                            v-model="actividad.nombre"
                          ></v-text-field>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on: tooltip }">
                              <v-icon
                                v-if="item.abreviatura != estados.SIN_ESTADO"
                                small
                                :color="prioridad(actividad.prioridad).color"
                                v-on="{ ...tooltip }"
                                class="mt-2"
                              >
                                mdi-checkbox-blank-circle
                              </v-icon>
                            </template>
                            <span>{{ prioridad(actividad.prioridad).nombre }}</span>
                          </v-tooltip>
                        </div>
                      </div>
                      <div
                        v-if="item.abreviatura == estados.SIN_ESTADO"
                        class="pt-4 col-4 col-lg-1 col-md-2 col-sm-2 px-0 pt-2 text-center d-flex justify-center align-center"
                      >
                        <v-menu
                          v-model="editing[`${item.abreviatura}${index2}prioridad`]"
                          :close-on-content-click="true"
                          offset-y
                        >
                          <template v-slot:activator="{ on: menu, attrs }">
                            <!--<v-btn plain :depressed="true" v-bind="attrs" v-on="{ ...menu }" small class="pa-0">-->
                            <v-chip
                              class="mx-2"
                              :color="prioridad(actividad.prioridad).color"
                              text-color="white"
                              v-bind="attrs"
                              v-on="{ ...menu }"
                              small
                              label
                            >
                              {{ prioridad(actividad.prioridad).nombre }}
                            </v-chip>
                            <!--</v-btn>-->
                          </template>
                          <v-list>
                            <v-list-item
                              dense
                              v-for="(prioridad, indexP) in prioridades"
                              v-bind:key="indexP"
                              link
                              @click="cambiarPrioridad(index, index2, prioridad.nombre)"
                            >
                              <v-list-item-title v-text="prioridad.nombre"></v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </div>
                      <div
                        v-if="item.abreviatura != estados.SIN_ESTADO"
                        class=" col-4 col-lg-1 col-md-2 col-sm-2 px-0 pl-1 pt-3 text-center d-flex justify-center align-center"
                      >
                        <v-menu
                          v-model="editing[`${item.abreviatura}${index2}responsable`]"
                          :close-on-content-click="false"
                          :close-on-click="closeOnContentResponsable"
                          :nudge-width="400"
                          :max-width="400"
                          @mousedown="closeOnContentResponsable = true"
                          offset-x
                          top
                        >
                          <template v-slot:activator="{ on: menu, attrs }">
                            <v-btn
                              plain
                              elevation="0"
                              :depressed="true"
                              v-bind="attrs"
                              v-on="{ ...menu }"
                              class="btn-menu pa-0 d-flex justify-center align-center"
                            >
                              <template v-for="(responsable, index3) in actividad.responsables">
                                <v-tooltip v-if="index3 < 2" bottom :key="`id-res-list-${index}-${index2}-${index3}`">
                                  <template v-slot:activator="{ on: tooltip }">
                                    <v-btn
                                      x-small
                                      fab
                                      dark
                                      v-bind="attrs"
                                      :style="`${index3 > 0 ? 'left: -' + 10 * index3 + 'px;' : ''}`"
                                      @click="edicionResponsables"
                                      v-on="{ ...menu, ...tooltip }"
                                      :color="item.color + ' lighten-1'"
                                      :class="`pa-0 ${index3 > 0 ? 'd-none d-md-block' : ''}`"
                                    >
                                      {{ iniciales(responsable.nombres) }}
                                    </v-btn>
                                  </template>
                                  <span>{{ responsable.nombres }}</span>
                                </v-tooltip>
                              </template>
                              <v-tooltip v-if="actividad.responsables.length > 2" bottom>
                                <template v-slot:activator="{ on: tooltip }">
                                  <v-btn
                                    x-small
                                    fab
                                    dark
                                    :color="item.color + ' lighten-3'"
                                    class="pa-0 d-none d-md-block"
                                    v-bind="attrs"
                                    v-on="{ ...menu, ...tooltip }"
                                    style="left: -20px;"
                                  >
                                    ...
                                  </v-btn>
                                </template>
                                <span>Ver más</span>
                              </v-tooltip>
                            </v-btn>
                          </template>
                          <v-card>
                            <div class="px-3 py-4">
                              <v-chip
                                :close="responsable.tiporesponsable != 1 && item.abreviatura != estados.REALIZADO"
                                small
                                class="pl-0 my-1 mr-1"
                                v-for="(responsable, index3) in actividad.responsables"
                                v-bind:key="index3"
                                @click:close="eliminarResponsable(actividad, index, index2, index3)"
                              >
                                <v-avatar :color="item.color + ' lighten-1'" size="36" class="mr-1">
                                  <span class="white--text">{{ iniciales(responsable.nombres) }}</span>
                                </v-avatar>
                                {{ responsable.nombres }}
                              </v-chip>
                            </div>
                            <v-divider v-if="item.abreviatura != estados.REALIZADO"></v-divider>
                            <div v-if="item.abreviatura != estados.REALIZADO" class="px-3 d-flex align-center">
                              <v-autocomplete
                                v-model="responsablesSeleccionados"
                                :search-input.sync="searchResponsable[index + '-' + index2]"
                                @change="searchResponsable[index + '-' + index2] = null"
                                full-width
                                :items="listaInvitados(actividad)"
                                class="mr-2 pt-0"
                                chips
                                label="Introduce un nombre..."
                                item-text="text"
                                item-value="value"
                                return-object
                                multiple
                              >
                                <!-- @mousedown="closeOnContentResponsable = false" -->
                              </v-autocomplete>
                              <!--<v-text-field class="pt-2 mr-2" dense placeholder="Introduce un nombre..."></v-text-field>-->
                              <v-btn
                                outlined
                                color="primary"
                                small
                                @click="agregarResponsables(actividad, index, index2)"
                              >
                                <v-icon small>mdi-send</v-icon>
                              </v-btn>
                            </div>
                            <!--<v-divider></v-divider>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn text @click="exitEdit(`${item.abreviatura}${index2}responsable`)">
                                Cancelar
                              </v-btn>
                              <v-btn color="primary" text @click="exitEdit(`${item.abreviatura}${index2}responsable`)">
                                Guardar
                              </v-btn>
                            </v-card-actions>-->
                          </v-card>
                        </v-menu>
                      </div>
                      <div
                        v-if="item.abreviatura != estados.SIN_ESTADO"
                        class="col-2 px-0 pt-4 text-center d-none d-lg-block align-center"
                      >
                        <v-alert
                          border="right"
                          :color="item.colorEstado"
                          dark
                          class="kanban-alert kanban-alert-detail px-0 py-1"
                        >
                          {{ item.nombreEstado }}
                        </v-alert>
                      </div>
                      <div
                        v-if="item.abreviatura != estados.SIN_ESTADO"
                        class="col-2 px-5 pt-4 text-center d-none d-lg-block align-center"
                      >
                        <v-menu
                          v-model="editing[`${item.abreviatura}${index2}fecha`]"
                          :close-on-click="!(menuDateFI || menuDateFV)"
                          :close-on-content-click="false"
                          :nudge-width="200"
                          transition="slide-x-transition"
                          offset-y
                          bottom
                          right
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-chip
                              class="white--text text-center"
                              small
                              v-bind="attrs"
                              chip
                              v-on="on"
                              @click="edit(`${item.abreviatura}${index2}fecha`, item.abreviatura, index, index2)"
                              :color="verificarColorFecha(fechasActividad(actividad), item.colorFecha)"
                            >
                              <v-icon v-if="item.abreviatura != estados.PENDIENTE" class="mr-1">
                                {{ verificarIconFecha(item.abreviatura, actividad) }}
                              </v-icon>
                              <span>{{ fechasActividad(actividad) }}</span>
                            </v-chip>
                          </template>
                          <v-card>
                            <v-list dense>
                              <v-list-item>
                                <v-form ref="formFechaInicio" v-model="validFormFechaInicio" class="w-100">
                                  <v-text-field
                                    v-model="actividad.fechaInicioAux"
                                    label="Fecha Inicio"
                                    ref="fechainicio"
                                    :rules="[rules.fechaInicio(actividad.fechaInicioAux, actividad.fechaVencAux)]"
                                    persistent-hint
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    @click="
                                      openDatePicker($event, {
                                        tipo: 'fi',
                                        index: index,
                                        index2: index2,
                                        referencia: actividad.referencia,
                                        estado: item.abreviatura
                                      })
                                    "
                                  ></v-text-field>
                                </v-form>
                              </v-list-item>
                              <v-list-item class="mb-2">
                                <v-form ref="formFechaVenc" v-model="validFormFechaVenc" class="w-100">
                                  <v-text-field
                                    v-model="actividad.fechaVencAux"
                                    label="Fecha Vencimiento"
                                    ref="fechavenc"
                                    :rules="[rules.fechaVenc(actividad.fechaVencAux, actividad.fechaInicioAux)]"
                                    persistent-hint
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    @click="
                                      openDatePicker($event, {
                                        tipo: 'fv',
                                        index: index,
                                        index2: index2,
                                        referencia: actividad.referencia,
                                        estado: item.abreviatura
                                      })
                                    "
                                  ></v-text-field>
                                </v-form>
                              </v-list-item>
                            </v-list>
                          </v-card>
                        </v-menu>
                      </div>
                      <div
                        v-if="item.abreviatura != estados.SIN_ESTADO"
                        class="col-2 col-lg-1 px-0 pt-2 text-center d-none d-lg-block align-center"
                      >
                        <v-menu
                          v-model="editing[`${item.abreviatura}${index2}archivo`]"
                          :close-on-content-click="false"
                          :nudge-width="300"
                          offset-y
                          bottom
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on: tooltip }">
                                <v-btn
                                  text
                                  icon
                                  v-bind="attrs"
                                  v-on="{ ...on, ...tooltip }"
                                  @click="edit(`${item.abreviatura}${index2}archivo`, item.abreviatura)"
                                >
                                  <v-icon
                                    :color="
                                      actividad.nombrefichero == '' || actividad.nombrefichero == null
                                        ? 'green darken-1'
                                        : 'indigo darken-1'
                                    "
                                  >
                                    {{
                                      actividad.nombrefichero == '' || actividad.nombrefichero == null
                                        ? 'mdi-plus-box'
                                        : 'mdi-folder-zip'
                                    }}
                                  </v-icon>
                                </v-btn>
                              </template>
                              <span>
                                {{
                                  actividad.nombrefichero == '' || actividad.nombrefichero == null
                                    ? 'Agregar archivo adjunto'
                                    : 'Archivo adjunto'
                                }}
                              </span>
                            </v-tooltip>
                          </template>
                          <v-card>
                            <v-list dense>
                              <v-list>
                                <v-list-item
                                  v-if="actividad.nombrefichero != '' && actividad.nombrefichero != null"
                                  link
                                  @click="descargarArchivo(actividad)"
                                >
                                  <v-icon class="mr-2">mdi-download</v-icon>
                                  <span>Descargar</span>
                                </v-list-item>
                                <v-list-item
                                  v-if="
                                    actividad.nombrefichero != '' &&
                                      actividad.nombrefichero != null &&
                                      puedePrevisualizar(actividad)
                                  "
                                  link
                                  @click="previsualizarArchivo(actividad)"
                                >
                                  <v-icon class="mr-2">mdi-file-eye</v-icon>
                                  <span>Previsualizar</span>
                                </v-list-item>
                                <v-list-item
                                  v-if="
                                    actividad.nombrefichero != '' &&
                                      actividad.nombrefichero != null &&
                                      item.abreviatura != estados.REALIZADO
                                  "
                                  link
                                  @click="eliminarArchivo(actividad, index, index2)"
                                >
                                  <v-icon class="mr-2">mdi-delete</v-icon>
                                  <span>Eliminar</span>
                                </v-list-item>
                                <v-divider
                                  v-if="
                                    item.abreviatura != estados.REALIZADO &&
                                      actividad.nombrefichero != '' &&
                                      actividad.nombrefichero != null
                                  "
                                />
                                <v-list-item v-if="item.abreviatura != estados.REALIZADO" link>
                                  <v-form
                                    :ref="`formArchivo`"
                                    v-model="validArchive"
                                    lazy-validation
                                    class="d-flex w-100"
                                  >
                                    <v-file-input
                                      v-model="archivo"
                                      show-size
                                      label="Archivo"
                                      clearable
                                      id="files"
                                      ref="files"
                                      class="mr-2"
                                      :rules="rulesArchive"
                                      prepend-icon="mdi-paperclip"
                                    ></v-file-input>
                                  </v-form>
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                      <v-btn
                                        color="primary"
                                        class="white--text mr-1 mt-3"
                                        small
                                        icon
                                        v-on="on"
                                        @click="subirArchivo(actividad, index, index2)"
                                      >
                                        <v-icon dark>mdi-send</v-icon>
                                      </v-btn>
                                    </template>
                                    <span>CARGAR ARCHIVO</span>
                                  </v-tooltip>
                                </v-list-item>
                              </v-list>
                            </v-list>
                          </v-card>
                        </v-menu>
                      </div>
                      <div
                        :class="
                          (item.abreviatura != estados.SIN_ESTADO
                            ? 'pt-1 col-2 col-lg-1'
                            : 'pt-2 col-2 col-lg-1 col-md-2 col-sm-2 pr-1') +
                            ' px-0 text-center d-flex justify-center align-center'
                        "
                      >
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on: tooltip }">
                            <v-btn small icon @click="editTarea(index, index2)" v-on="{ ...tooltip }">
                              <v-icon>
                                {{ item.abreviatura != estados.REALIZADO ? 'mdi-pencil-box-outline' : 'mdi-eye' }}
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>
                            {{ item.abreviatura != estados.REALIZADO ? 'Editar tarea' : 'Visualizar tarea' }}
                          </span>
                        </v-tooltip>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on: tooltip }">
                            <v-btn
                              small
                              icon
                              v-if="item.abreviatura != estados.REALIZADO"
                              @click="deleteTarea(index, index2)"
                              v-on="{ ...tooltip }"
                            >
                              <v-icon>
                                mdi-delete
                              </v-icon>
                            </v-btn>
                          </template>
                          <span>
                            Eliminar tarea
                          </span>
                        </v-tooltip>
                      </div>
                    </v-row>
                  </v-alert>
                </li>
              </transition-group>
            </Draggable>
            <v-alert
              v-if="item.abreviatura == estados.SIN_ESTADO"
              style="margin-left: 20px;"
              class="kanban-alert kanban-alert-add pl-3 mr-3"
              border="left"
              colored-border
              :color="item.color + ' lighten-3'"
              @click="agregarTarea(index)"
              elevation="2"
            >
              <v-row>
                <div class="col-12 pt-4">
                  <v-icon small class="ml-4 mr-1">
                    mdi-plus
                  </v-icon>
                  <span>Agregar Tarea</span>
                </div>
              </v-row>
            </v-alert>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-menu
        ref="menuDateFI"
        style="z-index: 10;"
        v-model="menuDateFI"
        :close-on-content-click="false"
        offset-y
        :position-x="positionXDate"
        :position-y="positionYDate"
        max-width="290px"
        min-width="auto"
      >
        <v-date-picker
          v-model="fechaAuxFI"
          locale="es-es"
          :first-day-of-week="1"
          no-title
          @input="editarFechaInicio"
        ></v-date-picker>
      </v-menu>
      <v-menu
        ref="menuDateFV"
        style="z-index: 10;"
        v-model="menuDateFV"
        :close-on-content-click="false"
        offset-y
        :position-x="positionXDate"
        :position-y="positionYDate"
        max-width="290px"
        min-width="auto"
      >
        <v-date-picker
          v-model="fechaAuxFV"
          locale="es-es"
          :first-day-of-week="1"
          no-title
          @input="editarFechaVencimiento"
        ></v-date-picker>
      </v-menu>
      <div v-if="showLoading" class="progress-background">
        <v-progress-circular :size="70" :width="7" color="primary" indeterminate></v-progress-circular>
      </div>
      <SincronizarOKR
        :dialog="dialogSincronizacion"
        :semana="semanaActual"
        :responsablesFiltro="responsablesFiltro"
        @listar="listarTareas"
        @close="closeSincronizacion"
      ></SincronizarOKR>
      <TareaForm
        v-if="indexEdit != null"
        :dialog="dialogTareaForm"
        :item="listas[indexEdit].actividades[index2Edit]"
        :abreviatura="listas[indexEdit].abreviatura"
        :listadoResponsables="listadoResponsables"
        :lista="listas[indexEdit]"
        :prioridades="prioridades"
        :estados="estados"
        :fechaInicioSemana="fechaInicioSemana"
        :fechaFinSemana="fechaFinSemana"
        @listarTareas="listarTareas"
        @close="closeForm"
      ></TareaForm>
      <PreviewDocument
        :dialog="dialogPreviewDocument"
        :item="itemPreview"
        @close="
          dialogPreviewDocument = false;
          itemPreview = null;
        "
      ></PreviewDocument>
    </v-col>
  </div>
</template>

<script>
import DashboardService from '../services/DashboardService';
import moment from 'moment';
import { decryptAES } from '@/components4x/utils/utils4x';
import Draggable from 'vuedraggable';
import Swal from 'sweetalert2';
import { v4 as uuidv4 } from 'uuid';
const ESTADOS = {
  SIN_ESTADO: 'SE',
  SIN_ESTADO_NOMBRE: 'Actividades',
  SIN_ESTADO_COLOR: 'teal',
  SIN_ESTADO_COLOR_ESTADO: 'grey',
  SIN_ESTADO_NOMBRE_ESTADO: 'Sin Estado',
  SIN_ESTADO_COLOR_FECHA: '',
  PENDIENTE: 'PE',
  PENDIENTE_NOMBRE: 'TO-DO: Qué hacer',
  PENDIENTE_COLOR: 'pink darken-2',
  PENDIENTE_COLOR_ESTADO: 'pink darken-2',
  PENDIENTE_NOMBRE_ESTADO: 'Pendiente',
  PENDIENTE_COLOR_FECHA: 'pink darken-2',
  PROCESO: 'PR',
  PROCESO_NOMBRE: 'DOING: Haciendo',
  PROCESO_COLOR: 'blue',
  PROCESO_COLOR_ESTADO: 'orange',
  PROCESO_NOMBRE_ESTADO: 'En Proceso',
  PROCESO_COLOR_FECHA: 'orange',
  REALIZADO: 'RE',
  REALIZADO_NOMBRE: 'DONE: Hecho',
  REALIZADO_COLOR: 'purple',
  REALIZADO_COLOR_ESTADO: 'green darken-1',
  REALIZADO_NOMBRE_ESTADO: 'Realizado',
  REALIZADO_COLOR_FECHA: 'green darken-1'
};

export default {
  name: 'KanbanLista',
  data: () => ({
    estados: ESTADOS,
    closeOnClickFechas: true,
    prioridades: [
      {
        nombre: 'Baja',
        color: 'green darken-2'
      },
      {
        nombre: 'Media',
        color: 'orange darken-2'
      },
      {
        nombre: 'Alta',
        color: 'red darken-2'
      }
    ],
    rules: {
      required: (value) => !!value || 'Campo requerido.',
      fechaInicio: (value, fechaVenc) => {
        if (value && fechaVenc)
          return value <= fechaVenc || 'Fecha inicio debe ser menor o igual a fecha de vencimiento';
        else return true;
      },
      fechaVenc: (value, fechaInicio) => {
        if (value && fechaInicio)
          return value >= fechaInicio || 'Fecha vencimiento debe ser mayor o igual a fecha de inicio';
        else return true;
      },
      intervaloSemanas(fechaVenc, inicioSemana, finSemana) {
        if (fechaVenc)
          return (
            (fechaVenc <= finSemana && fechaVenc >= inicioSemana) ||
            'Fecha vencimiento debe pertenecer a la semana actual'
          );
        else return true;
      }
    },
    validFormFechaInicio: true,
    validFormFechaVenc: true,
    panel: [0, 1, 2, 3],
    titulos: [],
    listadoTareas: [],
    listadoResponsables: [],
    responsablesSeleccionados: [],
    editing: {},
    editingResponsable: {},
    menu: false,
    selectedOpen: false,
    dialogTareaForm: false,
    semana: '',
    semanas: [],
    datos: {},
    showLoading: false,
    dashboardService: null,
    page: {
      title: 'OKR'
    },
    breadcrumbs: [
      {
        text: 'OKR',
        disabled: true
      },
      {
        text: 'Dashboard',
        disabled: true
      }
    ],
    menuFI: false,
    menuFV: false,
    archivo: null,
    rulesArchive: [
      (value) => !!value || 'Campo requerido.',
      (value) => !value || value.size < 25000000 || 'El archivo debe de tener menos de 25 MB!',
      (value) => {
        if (value === null || value === undefined) return true;
        const res = value.name.slice(value.name.lastIndexOf('.') + 1, value.name.length).toLowerCase();
        if (
          res != 'exe' &&
          res != 'sql' &&
          res != 'jar' &&
          res != 'air' &&
          res != 'src' &&
          res != 'vb' &&
          res != 'bat' &&
          res != 'dll' &&
          res != 'com' &&
          res != 'bin' &&
          res != 'apk' &&
          res != 'app' &&
          res != 'cgi' &&
          res != 'wsf' &&
          res != 'gadget' &&
          res != 'log' &&
          res != 'css' &&
          res != 'sqlite' &&
          res != 'psd' &&
          res != 'ai' &&
          res != 'bak' &&
          res != 'js' &&
          res != 'cmd'
        )
          return true;
        return 'No se permite ese tipo de archivos.';
      }
    ],
    cantidadActividades: [],
    indiceAnterior: null,
    indiceEstadoAnterior: null,
    dialogImportarArchivo: false,
    validArchive: true,
    dateFormatted: null,
    drag: false,
    closeOnContentResponsable: true,
    indexEdit: null,
    index2Edit: null,
    semanaActual: null,
    listas: [
      {
        abreviatura: ESTADOS.SIN_ESTADO,
        nombre: ESTADOS.SIN_ESTADO_NOMBRE,
        color: ESTADOS.SIN_ESTADO_COLOR,
        colorEstado: ESTADOS.SIN_ESTADO_COLOR_ESTADO,
        nombreEstado: ESTADOS.SIN_ESTADO_NOMBRE_ESTADO,
        colorFecha: ESTADOS.SIN_ESTADO_COLOR_FECHA,
        actividades: []
      },
      {
        abreviatura: ESTADOS.PENDIENTE,
        nombre: ESTADOS.PENDIENTE_NOMBRE,
        color: ESTADOS.PENDIENTE_COLOR,
        colorEstado: ESTADOS.PENDIENTE_COLOR_ESTADO,
        nombreEstado: ESTADOS.PENDIENTE_NOMBRE_ESTADO,
        colorFecha: ESTADOS.PENDIENTE_COLOR_FECHA,
        actividades: []
      },
      {
        abreviatura: ESTADOS.PROCESO,
        nombre: ESTADOS.PROCESO_NOMBRE,
        color: ESTADOS.PROCESO_COLOR,
        colorEstado: ESTADOS.PROCESO_COLOR_ESTADO,
        nombreEstado: ESTADOS.PROCESO_NOMBRE_ESTADO,
        colorFecha: ESTADOS.PROCESO_COLOR_FECHA,
        actividades: []
      },
      {
        abreviatura: ESTADOS.REALIZADO,
        nombre: ESTADOS.REALIZADO_NOMBRE,
        color: ESTADOS.REALIZADO_COLOR,
        colorEstado: ESTADOS.REALIZADO_COLOR_ESTADO,
        nombreEstado: ESTADOS.REALIZADO_NOMBRE_ESTADO,
        colorFecha: ESTADOS.REALIZADO_COLOR_FECHA,
        actividades: []
      }
    ],
    menuDateFI: false,
    menuDateFV: false,
    fechaAuxFI: null,
    fechaAuxFV: null,
    positionXDate: 0,
    positionYDate: 0,
    indexListEdit: null,
    indexActEdit: null,
    dialogSincronizacion: false,
    responsablesFiltro: null,
    searchResponsable: [],
    listadoResponsablesXPermisos: [],
    dialogPreviewDocument: false,
    itemPreview: null,
    fechaInicioSemana: null,
    fechaFinSemana: null
  }),
  watch: {
    async semana(val) {
      await this.initialize(val);
    }
  },
  computed: {},
  components: {
    Draggable,
    TareaForm: () => import('./TareaForm'),
    SincronizarOKR: () => import('./SincronizarOKR'),
    PreviewDocument: () => import('./PreviewDocument')
  },
  methods: {
    closeSincronizacion() {
      this.dialogSincronizacion = false;
    },
    openDialogSincronizacion() {
      this.dialogSincronizacion = true;
    },
    prioridad(prioridad) {
      let prioridadAux = this.prioridades.find((el) => el.nombre == prioridad);
      if (!prioridadAux) prioridadAux = { color: 'grey', nombre: '...' };
      return prioridadAux;
    },
    openDatePicker($event, ...el) {
      if (el[0].referencia == 'KAN' && el[0].estado != ESTADOS.REALIZADO) {
        this.positionXDate = $event.x;
        this.positionYDate = $event.y + 20;
        if (el[0].tipo == 'fv') {
          this.menuDateFV = !this.menuDateFV;
        } else {
          this.menuDateFI = !this.menuDateFI;
        }
        this.closeOnClickFechas = false;
        this.indexListEdit = el[0].index;
        this.indexActEdit = el[0].index2;
      }
    },
    iniciales(nombre) {
      let parts = nombre !== undefined ? nombre.split(' ') : [];
      let iniciales = '';
      parts.forEach((el, index) => {
        if (index < 2) iniciales += el[0];
      });
      return iniciales;
    },
    responsablesFiltrados(responsables) {
      return responsables.filter((el) => el.activo == 1);
    },
    verificarIconFecha(lista, actividad) {
      let fechaActual = new Date();

      return this.fechasActividad(actividad) == ''
        ? ''
        : lista == ESTADOS.REALIZADO
        ? 'mdi-check'
        : lista == ESTADOS.PROCESO
        ? actividad.fechaVenc >= fechaActual
          ? 'mdi-alert'
          : 'mdi-progress-clock'
        : '';
    },
    verificarColorFecha(fechas, colorFecha) {
      return fechas == '' ? 'grey' : colorFecha;
    },
    async initialize(semana) {
      this.datos = {};
      this.showLoading = true;
      const emp = decryptAES(localStorage.getItem('emp'));
      const res = await this.dashboardService.get().execResource('dashboard', {
        idempresa: emp,
        semana: semana,
        anio: moment().format('YYYY')
      });
      this.datos = res;
      this.showLoading = false;
    },
    async semanasItems() {
      const res = await this.dashboardService.get().execResource('fecha', {
        fecha: moment().year() + '-12-31'
      });
      for (let i = 1; i <= res; i++) {
        this.semanas.push(i);
      }
      this.semana = await this.dashboardService.get().execResource('fecha', {
        fecha: moment().format('YYYY-MM-DD')
      });
    },
    async cambiarEstado(idtarea, estado, posicion) {
      const res = await this.dashboardService.post().execResource('cambiarEstado', {
        idtarea: idtarea,
        idresponsable: this.responsablesFiltro,
        idestado: estado,
        posicion: posicion,
        semana: this.semanaActual
      });
      this.listas.forEach((el1, index1) => {
        el1.actividades.forEach((el2, index2) => {
          if (el2.idtarea == idtarea) {
            this.listas[index1].actividades[index2].idestado = estado;
            this.listas[index1].actividades[index2].estado = estado;
          }
        });
      });
      if (!res.status) {
        this.alertDialog('error', res.data);
        await this.listarTareas();
      } else {
        if (estado == 'RE') await this.listarTareas();
        this.$emit('actualizarLista', this.listas);
      }
    },
    async alertDialog(type, title) {
      return await Swal.fire({
        icon: type,
        title: title,
        confirmButtonText: `OK`
      });
    },
    revertir(element, indiceEstadoAnterior, indiceEstadoNuevo, newIndex) {
      let listaAct = [];
      listaAct = this.listas;
      listaAct[indiceEstadoAnterior].actividades.splice(this.indiceAnterior, 0, element);
      listaAct[indiceEstadoNuevo].actividades.splice(newIndex, 1);
      this.listas = [];
      this.listas = listaAct;
    },
    startDrag($event) {
      this.indiceAnterior = $event.oldIndex;
      this.cantidadActividades = [];
      this.listas.forEach((el) => {
        this.cantidadActividades.push(el.actividades.length);
      });
      this.drag = true;
    },
    endDrag() {
      this.drag = false;
    },
    async changeDraggable($event) {
      if ($event.added || $event.moved) {
        let element = $event.added ? $event.added.element : $event.moved.element;
        let estadoNuevo = null,
          indiceEstadoAnterior = null,
          indiceEstadoNuevo = null;
        this.listas.forEach((el, index) => {
          if (el.actividades.length > this.cantidadActividades[index]) {
            estadoNuevo = el.abreviatura;
            indiceEstadoNuevo = index;
          }
          if (el.actividades.length < this.cantidadActividades[index]) {
            indiceEstadoAnterior = index;
          }
          if ($event.moved) {
            estadoNuevo = element.idestado;
          }
        });
        let newIndex = $event.added ? $event.added.newIndex : $event.moved.newIndex;
        if ($event.added) {
          if (this.listas[indiceEstadoAnterior].abreviatura != ESTADOS.REALIZADO) {
            if (estadoNuevo == ESTADOS.REALIZADO) {
              if (
                element.nombre != '' &&
                element.nombre != null &&
                element.nombrefichero != '' &&
                element.nombrefichero != null &&
                element.fechaInicio != '' &&
                element.fechaInicio != null &&
                element.fechaVenc != '' &&
                element.fechaVenc != null
              ) {
                if (element.fechaVenc <= this.fechaFinSemana && element.fechaVenc >= this.fechaInicioSemana) {
                  const confirmed = await Swal.fire({
                    icon: 'warning',
                    title: 'Al pasar a realizada la tarea no se podrá revertir los cambios',
                    confirmButtonText: 'Aceptar',
                    cancelButtonText: 'Cancelar',
                    showCancelButton: true
                  });
                  if (confirmed.isConfirmed) {
                    this.cambiarEstado(element.idtarea, estadoNuevo, newIndex + 1);
                  } else {
                    this.revertir(element, indiceEstadoAnterior, indiceEstadoNuevo, newIndex);
                  }
                } else {
                  this.revertir(element, indiceEstadoAnterior, indiceEstadoNuevo, newIndex);
                  this.alertDialog(
                    'error',
                    `Fecha de vencimiento debe pertenecer a la semana actual (${this.fechaInicioSemana} | ${this.fechaFinSemana})`
                  );
                }
              } else {
                this.revertir(element, indiceEstadoAnterior, indiceEstadoNuevo, newIndex);
                let mensaje = '';
                mensaje =
                  element.nombre == '' || element.nombre == null ? 'Complete el campo Título de la tarea' : mensaje;
                mensaje = element.nombrefichero == '' || element.nombrefichero == null ? 'Adjunte un archivo' : mensaje;
                mensaje =
                  element.fechaInicio == '' || element.fechaInicio == null
                    ? 'Complete el campo Fecha de Inicio de la tarea'
                    : mensaje;
                mensaje =
                  element.fechaVenc == '' || element.fechaVenc == null
                    ? 'Complete el campo Fecha de Vencimiento de la tarea'
                    : mensaje;
                this.alertDialog('error', `${mensaje} para pasarlo a DONE: Hecho`);
              }
            } else {
              if (estadoNuevo == ESTADOS.PROCESO || estadoNuevo == ESTADOS.PENDIENTE) {
                this.listas[indiceEstadoNuevo].actividades[newIndex].fechaInicio = moment().format('YYYY-MM-DD');
              } else {
                this.listas[indiceEstadoNuevo].actividades[newIndex].fechaInicio = null;
              }
              let actividadEdit = this.listas[indiceEstadoNuevo].actividades[newIndex];
              this.cambiarEstado(element.idtarea, estadoNuevo, newIndex + 1);
              await this.editarDatos({
                idtarea: actividadEdit.idtarea,
                idempresa: actividadEdit.idempresa,
                fechainicio: actividadEdit.fechaInicio,
                idresponsable: this.responsablesFiltro
              });
            }
          } else {
            this.revertir(element, indiceEstadoAnterior, indiceEstadoNuevo, newIndex);
            this.alertDialog('error', 'No puede realizar cambios en una tarea ya realizada');
          }
        } else {
          this.cambiarEstado(element.idtarea, estadoNuevo, newIndex + 1);
        }
      }
    },
    async agregarTarea(index) {
      let responsable = this.listadoResponsables.find((el) => el.idresponsable == this.responsablesFiltro);
      let actividad = {
        idtarea: uuidv4(),
        nombre: 'SIN TÍTULO',
        prioridad: 'Baja',
        responsables: [],
        fechainicio: null,
        fechavencimiento: null,
        fechaInicio: null,
        fechaVenc: null,
        semana: this.semanaActual,
        idestado: 'SE',
        estado: 'SE',
        referencia: 'KAN',
        idresponsable: this.responsablesFiltro
      };
      this.listas[index].actividades.push({ ...actividad, responsables: [{ ...responsable, tiporesponsable: 1 }] });
      await this.editarDatos(actividad, 1);
      //await this.listarTareas();
    },
    editTarea(index, index2) {
      this.indexEdit = index;
      this.index2Edit = index2;
      this.dialogTareaForm = true;
    },
    closeForm() {
      this.dialogTareaForm = false;
    },
    async edit(element, abreviatura, indexList = -1, indexAct = -1) {
      if (abreviatura != ESTADOS.REALIZADO) {
        this.editing[`${element}`] = true;
        this.editing = { ...this.editing };
        await this.$nextTick(() => {
          if (this.$refs[`${element}`]) this.$refs[`${element}`][0].focus();
        });
        if (element.includes('fecha')) {
          this.listas[indexList].actividades[indexAct].fechaInicioAux = this.listas[indexList].actividades[
            indexAct
          ].fechaInicio;
          this.listas[indexList].actividades[indexAct].fechaVencAux = this.listas[indexList].actividades[
            indexAct
          ].fechaVenc;
        }
      }
      if (element.includes('fecha')) {
        for (let el in this.editing) {
          if (el != element) this.editing[`${el}`] = false;
        }
      }
    },
    async editarDatos(objeto, nuevo = 0) {
      const res = await this.dashboardService.post().execResource('editarTarea', objeto);
      if (!res.status) {
        this.alertDialog('error', res.data);
        await this.listarTareas();
      } else {
        if (nuevo == 1) {
          this.$emit('actualizarLista', this.listas);
        }
      }
    },
    async cambiarPrioridad(indexListEdit, indexActEdit, prioridad) {
      this.listas[indexListEdit].actividades[indexActEdit].prioridad = prioridad;
      let actividadEdit = this.listas[indexListEdit].actividades[indexActEdit];
      await this.editarDatos({
        idtarea: actividadEdit.idtarea,
        idempresa: actividadEdit.idempresa,
        prioridad: actividadEdit.prioridad,
        idresponsable: this.responsablesFiltro
      });
    },
    async editarFechaInicio($event) {
      this.menuDateFI = false;
      this.listas[this.indexListEdit].actividades[this.indexActEdit].fechaInicioAux = $event;
      await this.$nextTick(() => {
        console.log(this.$refs.formFechaInicio[0].validate(), 'validate');
        if (this.$refs.formFechaInicio[0].validate()) {
          this.listas[this.indexListEdit].actividades[this.indexActEdit].fechaInicio = $event;
          let actividadEdit = this.listas[this.indexListEdit].actividades[this.indexActEdit];
          this.editarDatos({
            idtarea: actividadEdit.idtarea,
            idempresa: actividadEdit.idempresa,
            fechainicio: actividadEdit.fechaInicio,
            idresponsable: this.responsablesFiltro
          });
          this.closeOnClickFechas = true;
        }
      });
    },
    async editarFechaVencimiento($event) {
      this.menuDateFV = false;
      this.listas[this.indexListEdit].actividades[this.indexActEdit].fechaVencAux = $event;
      await this.$nextTick(() => {
        console.log(this.$refs.formFechaVenc[0].validate(), 'validate');
        if (this.$refs.formFechaVenc[0].validate()) {
          this.listas[this.indexListEdit].actividades[this.indexActEdit].fechaVenc = $event;
          let actividadEdit = this.listas[this.indexListEdit].actividades[this.indexActEdit];
          this.editarDatos({
            idtarea: actividadEdit.idtarea,
            idempresa: actividadEdit.idempresa,
            fechavencimiento: actividadEdit.fechaVenc,
            idresponsable: this.responsablesFiltro
          });
          this.closeOnClickFechas = true;
        }
      });
    },
    async exitEdit(element, actividad) {
      this.editing[`${element}`] = false;
      actividad.nombre = actividad.nombre.toUpperCase();
      await this.editarDatos({
        idtarea: actividad.idtarea,
        idempresa: actividad.idempresa,
        nombre: actividad.nombre,
        idresponsable: this.responsablesFiltro
      });
    },
    async deleteTarea(indexSec, indexAct) {
      const confirmed = await Swal.fire({
        icon: 'warning',
        title: '¿Está seguro de eliminar la tarea?',
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
        showCancelButton: true
      });
      if (confirmed.isConfirmed) {
        let idtarea = this.listas[indexSec].actividades[indexAct].idtarea;
        this.listas[indexSec].actividades.splice(indexAct, 1);
        const res = await this.dashboardService.delete().execResource('eliminarTarea', {
          idtarea: idtarea,
          idresponsable: this.responsablesFiltro
        });
        if (!res.status) {
          this.alertDialog('error', res.data);
          await this.listarTareas();
        } else {
          this.$emit('actualizarLista', this.listas);
        }
      }
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split('/');
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
    },
    edicionResponsables() {
      this.responsablesSeleccionados = [];
    },
    async editarInvitados(actividad, responsables, accion) {
      responsables = responsables.map((el) => {
        return { ...el, accion: accion };
      });
      const res = await this.dashboardService.post().execResource('agregarInvitados', {
        idempresa: actividad.idempresa,
        idtarea: actividad.idtarea,
        invitados: responsables
      });
      if (!res.status) this.alertDialog('error', res.data);
      this.responsablesSeleccionados = [];
    },
    async eliminarResponsable(actividad, index, index2, index3) {
      this.closeOnContentResponsable = false;
      const confirmed = await Swal.fire({
        icon: 'warning',
        title: '¿Está seguro de eliminar el responsable?',
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
        showCancelButton: true
      });
      if (confirmed.isConfirmed) {
        let responsable = this.listas[index].actividades[index2].responsables[index3];
        this.listas[index].actividades[index2].responsables.splice(index3, 1);
        await this.editarInvitados(actividad, [responsable], 'E');
      }
      this.closeOnContentResponsable = true;
    },
    async agregarResponsables(actividad, index, index2) {
      this.closeOnContentResponsable = false;
      const confirmed = await Swal.fire({
        icon: 'warning',
        title: '¿Está seguro de agregar los responsables?',
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
        showCancelButton: true
      });
      this.closeOnContentResponsable = true;
      if (confirmed.isConfirmed) {
        this.responsablesSeleccionados.forEach((el) => {
          this.listas[index].actividades[index2].responsables.push(el);
        });
        await this.editarInvitados(actividad, this.responsablesSeleccionados, 'N');
        this.responsablesSeleccionados = [];
        //await this.listarTareas();
      }
    },
    async descargarArchivo(actividad) {
      const confirmed = await Swal.fire({
        icon: 'warning',
        title: '¿Está seguro de descargar el archivo?',
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
        showCancelButton: true
      });
      if (confirmed.isConfirmed) {
        const token = localStorage.getItem('token');
        await this.dashboardService.downloadResource(
          'descargarFichero',
          {
            idtarea: actividad.idtarea,
            token: token
          },
          typeof actividad.nombrefichero == 'object' ? actividad.nombrefichero.name : actividad.nombrefichero
        );
      }
    },
    async subirArchivo(actividad, index, index2) {
      if (this.$refs.formArchivo[0].validate()) {
        const confirmed = await Swal.fire({
          icon: 'warning',
          title: '¿Está seguro de subir el archivo?',
          confirmButtonText: 'Aceptar',
          cancelButtonText: 'Cancelar',
          showCancelButton: true
        });
        if (confirmed.isConfirmed) {
          this.showLoading = true;
          this.listas[index].actividades[index2].nombrefichero = this.archivo.name;
          const formData = new FormData();
          formData.append('idtarea', actividad.idtarea);
          formData.append('idempresa', actividad.idempresa);
          formData.append('fichero', this.archivo);
          formData.append('editarfichero', 1);
          formData.append('idresponsable', this.responsablesFiltro);
          await this.editarDatos(formData);
          await new Promise(() =>
            setTimeout(() => {
              this.showLoading = false;
              this.archivo = null;
            }, 3000)
          );
        }
        this.archivo = null;
      }
    },
    async eliminarArchivo(actividad, index, index2) {
      const confirmed = await Swal.fire({
        icon: 'warning',
        title: '¿Está seguro de eliminar el archivo?',
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar',
        showCancelButton: true
      });
      if (confirmed.isConfirmed) {
        this.listas[index].actividades[index2].nombrefichero = '';
        const formData = new FormData();
        formData.append('idtarea', actividad.idtarea);
        formData.append('idempresa', actividad.idempresa);
        formData.append('fichero', '');
        formData.append('editarfichero', 1);
        formData.append('idresponsable', this.responsablesFiltro);
        this.editarDatos(formData);
      }
    },
    async obtenerSemanaActual() {
      this.semanaActual = null;
      let fechaActual = moment().format('YYYY-MM-DD');
      const res = await this.dashboardService.get().execResource('semana', {
        fecha: fechaActual
      });
      this.semanaActual = Number(res[0].semana);
      this.fechaInicioSemana = res[0].iniciosemana;
      this.fechaFinSemana = res[0].finsemana;
    },
    async listarTareas() {
      this.showLoading = true;
      this.listadoTareas = [];
      //this.listas = [];
      const res = await this.dashboardService.get().execResource('listadoTareas', {
        semana: this.semanaActual,
        idresponsable: this.responsablesFiltro //.join(',')
      });
      this.listadoTareas = res.tarea;
      //if (this.listadoTareas.length > 0)
      await this.estructurarListadoTareas();
      //this.$emit('porcentajesCumplimiento', res.procentajeCumplimiento[0]);
      this.$emit('actualizarLista', this.listas);
      //await this.allPanel();
      this.showLoading = false;
    },
    fechasActividad(actividad) {
      let fechaInicio = actividad.fechaInicio != null ? moment(actividad.fechaInicio).format('MMM DD') : '';
      let fechaVenc = actividad.fechaVenc != null ? moment(actividad.fechaVenc).format('MMM DD') : '';
      let guion = fechaInicio != '' && fechaVenc != '' ? ' - ' : '';
      return fechaInicio + guion + fechaVenc;
    },
    listaInvitados(actividad) {
      let nuevosInvitados = [];
      this.listadoResponsables.forEach((el) => {
        let existe = 0;
        actividad.responsables.forEach((el2) => {
          if (el.idresponsable == el2.idresponsable) existe = 1;
        });
        if (existe == 0) nuevosInvitados.push(el);
      });
      return nuevosInvitados;
    },
    async cargarListadoInvitados() {
      const res = await this.dashboardService.get().execResource('listarInvitados');
      this.listadoResponsables = res.map((el) => {
        return { value: el.idresponsable, text: el.nombres, ...el };
      });
      /*
      this.responsablesFiltro = this.listadoResponsables.find(
        (el) => el.idresponsable == decryptAES(localStorage.getItem('user'))
      ).value;*/
    },
    async cargarResponsablesXPermisos() {
      const res = await this.dashboardService.get().execResource('listarResponsablesXPermisos');
      this.listadoResponsablesXPermisos = res.map((el) => {
        return { value: el.idresponsable, text: el.nombres, ...el };
      });

      this.responsablesFiltro = this.listadoResponsablesXPermisos.find(
        (el) => el.idresponsable == decryptAES(localStorage.getItem('user'))
      ).value;
    },
    async estructurarListadoTareas() {
      this.listas = this.listas.map((el) => {
        return { ...el, actividades: [] };
      });

      if (this.listadoTareas.length > 0) {
        this.listadoTareas.forEach((el) => {
          let elemento = {
            ...el,
            semana: Number(el.semana),
            responsables: el.invitados,
            estado: el.idestado,
            fechaInicio: el.fechainicio,
            fechaVenc: el.fechavencimiento,
            fechaInicioAux: el.fechainicio,
            fechaVencAux: el.fechavencimiento,
            fechaFin: el.fechafin,
            nombre: el.nombre.toUpperCase()
          };

          switch (el.idestado) {
            case ESTADOS.SIN_ESTADO:
              this.listas[0].actividades.push(elemento);
              break;
            case ESTADOS.PENDIENTE:
              this.listas[1].actividades.push(elemento);
              break;
            case ESTADOS.PROCESO:
              this.listas[2].actividades.push(elemento);
              break;
            case ESTADOS.REALIZADO:
              this.listas[3].actividades.push(elemento);
              break;
          }
        });
      }
    },
    async allPanel() {
      this.panel = [0, 1, 2, 3];
    },
    nonePanel() {
      this.panel = [];
    },
    previsualizarArchivo(actividad) {
      let url = process.env.VUE_APP_BACK_URL + 'storage/kanban/' + actividad.idtarea;
      let partes = actividad.nombrefichero.split('.');
      let extension = partes[partes.length - 1];
      this.itemPreview = { extension: extension, previewURL: url };
      this.dialogPreviewDocument = true;
    },
    puedePrevisualizar(actividad) {
      let partes = actividad.nombrefichero.split('.');
      let extension = partes[partes.length - 1];
      if (
        extension === 'doc' ||
        extension === 'docx' ||
        extension === 'ppt' ||
        extension === 'pptx' ||
        extension === 'xls' ||
        extension === 'xlsx' ||
        extension === 'pdf' ||
        extension === 'jpg' ||
        extension === 'jpeg' ||
        extension === 'png'
      ) {
        return true;
      }
      return false;
    },
    async focusInputNombre(index, index2, abreviatura) {
      this.editing[`${abreviatura}${index2}nombre`] = true;
      await this.$nextTick(() => {
        if (this.$refs[`${abreviatura}${index2}nombre`]) {
          this.$refs[`${abreviatura}${index2}nombre`][0].focus();
        }
      });
      if (this.listas[index].actividades[index2].nombre == 'SIN TÍTULO')
        this.listas[index].actividades[index2].nombre = '';
    }
  },
  async created() {
    this.dashboardService = this.$httpService(new DashboardService(), this);
    this.showLoading = true;
    await this.obtenerSemanaActual();
    await this.cargarListadoInvitados();
    await this.cargarResponsablesXPermisos();
    await this.listarTareas();
    this.showLoading = false;
    /*await this.semanasItems();*/
  }
};
</script>

<style lang="scss">
.expansion-panel-icon {
  order: 0;
  margin-right: 0.5rem;
}

.expansion-panel-header {
  order: 1;
}

.v-alert.kanban-alert {
  list-style: none;
  margin-bottom: 4px;
  padding: 4px;
  padding-left: 16px;
  background-color: #f6f6f600;
  font-size: 13px;
  cursor: pointer !important;
}

.v-alert.kanban-alert:hover {
  background-color: #f7f7f78b;
}

.v-alert.kanban-alert-add {
  background-color: #ffffff;
  cursor: pointer !important;
}

.v-alert.kanban-alert.v-sheet {
  /*box-shadow: rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px !important;*/
  box-shadow: rgba(72, 83, 103, 0.25) 0px 1px 1px, rgba(113, 123, 140, 0.13) 0px 0px 1px 1px !important;
  border-radius: 0.5px;
}

.v-alert.kanban-alert.kanban-alert-detail {
  font-size: 12px;
  padding: 2px 16px 0 16px;
}

.kanban-expansion-panels .v-expansion-panel--active > .v-expansion-panel-header {
  min-height: 0px;
}

.kanban-celda-left {
  background-color: #f6f6f6;
  border-top-left-radius: 10px;
}

.kanban-celda-right {
  background-color: #f6f6f6;
  border-top-right-radius: 10px;
}

.ghost {
  /*opacity: 0.5;*/
  margin-bottom: 0px;
  margin-left: 5.5px !important;
  background-color: rgb(255, 255, 255) !important;
}

.kanban-expansion-panels li {
  list-style: none;
  margin-bottom: 4px;
  background-color: #f0f0f0;
  padding: 4px;
  padding-left: 16px;
  font-size: 13px;
  margin-left: 8px;
  cursor: pointer !important;
}

.kanban-expansion-panels ul span {
  min-height: 10px;
  display: block;
}

.btn-menu .v-btn__content {
  display: flex !important;
}

.v-application .border--white {
  border-style: solid;
  border-width: 2px;
  border-color: white !important;
}

.v-list-responsables .v-list-item {
  min-height: 33px !important;
}

.v-btn--plain:not(.v-btn--active):not(.v-btn--loading):not(:focus):not(:hover) .v-btn__content {
  opacity: unset;
}

.uppercase input {
  text-transform: uppercase;
}
/*
.moving-card {
  @apply opacity-50 bg-gray-100 border border-blue-500;
}*/
</style>
