class DashboardService {
  constructor() {}
  resources = () => ({
    semana: {
      uri: `/v1/obtener-semana`,
      method: ['get']
    },
    listadoTareas: {
      uri: `/v1/kanban/listar-tarea`,
      method: ['get']
    },
    cambiarEstado: {
      uri: `/v1/kanban/actualizar-estado-tarea`,
      method: ['post']
    },
    listarInvitados: {
      uri: `/v1/kanban/listar-invitados-tareas`,
      method: ['get']
    },
    agregarInvitados: {
      uri: `/v1/kanban/agregar-invitados`,
      method: ['post']
    },
    eliminarTarea: {
      uri: `/v1/kanban/eliminar-tarea`,
      method: ['delete']
    },
    editarTarea: {
      uri: `/v1/kanban/crear-tarea`,
      method: ['post']
    },
    descargarFichero: {
      uri: `/v1/kanban/descargararchivo`,
      method: ['get']
    },
    listarTareasPlanificadas: {
      uri: `/v1/kanban/listar-tareas-planificadas-okr`,
      method: ['get']
    },
    sincronizarTareasPlanificadas: {
      uri: `/v1/kanban/sincronizar-tarea`,
      method: ['post']
    },
    listarResponsablesXPermisos: {
      uri: `/v1/kanban/listar-responsable-permiso`,
      method: ['get']
    }
  });
}

export default DashboardService;
